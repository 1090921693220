import { computed, ComputedRef, nextTick, ref, watch } from 'vue';
import { useExperimentStore, useReadyStore, useRoutingStore, useUserStore } from './useStores';

import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { SnowplowExperimentContext, TrackingProviderPropertiesInterface } from '@/helpers/tracking/providers';

import type { ToValue as ToVariants } from '@/helpers/types';
import type { ExperimentTrackEventOptions } from '@/components/experiments/Core/types';

type VariantsDefinition<T extends string> = { CONTROL: 'control' } & { [key: string]: T };

interface UseExperimentOptions<T extends string = string> {
	/** Name and ID of the experiment */
	name: string;

	/** Object listing all the variants in this experiment. The CONTROL variant is required. */
	variants: VariantsDefinition<T>;

	/** Callback to modify the experiment trigger conditions. */
	shouldTrigger?: () => boolean;

	/** Callback to check if the experiment is ready to be activated. */
	isExperimentReady?: () => boolean;

	readyOn?: string[];
}

let resolve: <T>(val: T) => void;
const areExperimentsReady = new Promise(res => (resolve = res));

/** Use Experiment */
export function useExperiment<T extends UseExperimentOptions>(options: T) {
	const { name, variants, shouldTrigger = () => true, isExperimentReady = () => true, readyOn = [] } = options;

	const { registerExperiment, triggerExperiment, activeVariantsWithControlGroup } = useExperimentStore();

	if (name == null || variants == null) {
		throw `[useExperiment] Name and Variants are required to create an experiment.`;
	}

	type Variants = 'control' | ToVariants<T['variants']>;

	const activeVariant = computed<Variants | null>(() => activeVariantsWithControlGroup.value?.[name] ?? null);

	function getExperimentContext({ version = '1', variant }: { version?: string; variant: Variants }) {
		return new SnowplowExperimentContext(name, version, variant);
	}

	type TrackingOptions = ExperimentTrackEventOptions<Variants>;
	function trackEvent({ version = '1', action, label, variant, contexts = [], value, property }: TrackingOptions) {
		const activeVariant = variant ?? activeVariantsWithControlGroup.value[name] ?? null;

		// not in experiment
		if (activeVariant == null) return;

		const properties: TrackingProviderPropertiesInterface = { action, label, value, property };
		if (action === 'impression') properties.nonInteraction = true;

		TrackingHelper.trackEvent('onpage_test', properties, [
			...contexts,
			getExperimentContext({ version, variant: activeVariant }),
		]);
	}

	const { experimentsLoaded } = useReadyStore();

	watch(experimentsLoaded as ComputedRef<boolean>, isReady => resolve(isReady));

	const hasActivated = ref(false);
	async function ready() {
		await areExperimentsReady;

		// only set ready when the experiment hasn't been activated before (only one try per session per experiment)
		if (hasActivated.value || activeVariant.value) return;
		hasActivated.value = true;

		if (!shouldTrigger()) return;
		triggerExperiment({ experimentData: { name, variants } });
	}

	const { activeRoute } = useRoutingStore();
	async function onRouteChange() {
		// needs to wait for the next tick otherwise ready is sent before 'registerExperiment'
		await nextTick();

		if (readyOn.length === 0) {
			ready();
		} else if (activeRoute.value != null) {
			if (readyOn.includes(activeRoute.value.name) && isExperimentReady()) {
				ready();
			}
		}
	}

	watch(activeRoute, async () => onRouteChange(), { immediate: true });

	const { preferredExperiments } = useUserStore();
	watch(preferredExperiments, () => {
		hasActivated.value = false;
		registerExperiment({ name, variants });
		onRouteChange();
	});

	return {
		activeVariant,
		getExperimentContext,
		trackEvent,
	};
}
