import { base64EncodeUrl, btoa } from '@/helpers/base64-helper';
import { isClickoutOfferDetails } from '@/interfaces/snowplow/clickout-context';
import { SnowplowClickoutContextAffiliateLink } from '@/helpers/tracking/providers';
import { TrackingAppId, TrackingHelper } from '@/helpers/tracking/tracking-helper';

import type { SportType } from '@/@types/graphql-types';
import type { BuyboxParam } from '@/helpers/offer-helper';
import type { SnowplowContext } from '@/helpers/tracking/providers';
import type { ClickoutOfferDetails } from '@/interfaces/snowplow/clickout-context';

import { useAmazonStreamBountyExp } from '@/components/experiments/AmazonStreamBountyExp';

type Maybe<T> = T | null | undefined;

/**
 * Wrapper around the built-in URL class with a fluent interface for setting
 * query parameters that safely ignores falsy values.
 */
export interface UrlHelper<Parameter = string> {
	/** Reference to the underlying URL class. */
	url: URL;

	/** Holds any error that may have happened during instantiation. */
	error: Error | null;

	/**
	 * Set a URL parameter, if the value is falsy, the parameter will not be added.
	 * **Note that parameter values are automatically encoded.**
	 */
	set<Value extends Maybe<string | number>>(parameter: Parameter, value: Value): UrlHelper<Parameter>;

	/** Get back the value of a parameter on the URL Helper. */
	get(parameter: Parameter): string | null;

	/** Returns the URL as a string. */
	toString(): string;
}

export class ClassicUrl implements UrlHelper {
	#url: URL;
	#error: Error | null = null;

	/**
	 * A helper class around the built-in URL class that offers a fluent
	 * interface (method chaining) for setting query parameters. It
	 * safely ignores null or empty query param values.
	 *
	 * @param urlString A valid string URL or exisiting URL class.
	 */
	constructor(urlString: Maybe<string | URL>) {
		try {
			if (urlString == null) {
				throw new Error('[ClassicUrl] "urlString" must be a string and valid URL.');
			}

			this.#url = new URL(urlString);
		} catch (e: any) {
			console.warn(e.message ?? '[ClassicUrl] The URL String passed to URL Helper is an invalid URL');
			this.#error = e;
		}
	}

	/** Reference to the underlying URL class. */
	get url() {
		return this.#url;
	}

	/** Holds any error that may have happened during instantiation. */
	get error() {
		return this.#error;
	}

	/** Set a URL parameter, if the value is falsy, the parameter will not be added. */
	set(parameter: string, value: Maybe<string | number>): ClassicUrl {
		if (value) {
			this.#url.searchParams.set(parameter, value.toString());
		}

		return this;
	}

	/** Get back the value of a parameter on the Classic URL. */
	get(parameter: string): string | null {
		return this.#url.searchParams.get(parameter);
	}

	/** Returns the Classic URL as a string. */
	toString() {
		return this.#url.toString();
	}
}

// JustWatch UCT with a different subdomain to bypass ad blockers
const CLICK_URL = 'https://d.justwatch.com' as const;

type Offer = Maybe<{ standardWebURL?: Maybe<string> }>;
type ClickoutUrlParam =
	| 'cx'
	| 'r'
	| 'sid'
	| 'uct_buybox'
	| 'uct_country'
	| 'uct_ct'
	| 'uct_itsct'
	| 'uct_sport'
	| 'uct_tryout'
	| 'uct_web_app_version'
	| 'utm_campaign'
	| 'utm_medium'
	| 'utm_source'
	| 'uct_sr';

export class ClickoutUrl implements UrlHelper<ClickoutUrlParam> {
	#url: ClassicUrl;
	#error: Error | null = null;

	/**
	 * A helper class for Clickout URLs (links that pass through JW UCT).
	 * It uses a fluent interface (method chaining) and handles adding contexts,
	 * default tracking, and null or empty parameter values
	 *
	 * @param offer Any object that, at least, has "standardWebURL"
	 * @param options Additional, optional configuration for a clickout url
	 * @param options.fallback URL to use if standardWebURL is falsy
	 * @param options.country Country to check for UCT bypassing, if not given it will use `uct_country`
	 */
	constructor(offer: Offer | (Offer & ClickoutOfferDetails), { fallback = '' }: { fallback?: string } = {}) {
		const clickUrl = JW_CONFIG.CLICK_URL.includes('moviecycle') ? JW_CONFIG.CLICK_URL : CLICK_URL;
		this.#url = new ClassicUrl(`${clickUrl}/a`);
		this.#error = this.#url.error;

		// adds app version information to the URL as a query parameter
		this.set('uct_web_app_version', TrackingAppId);

		// May need to be encoded due to click tags provided by clients
		this.set('r', offer?.standardWebURL ?? fallback);

		const clickoutContext = isClickoutOfferDetails(offer)
			? SnowplowClickoutContextAffiliateLink.fromProviderOffer(offer)
			: undefined;

		// AMAZON_STREAM_BOUNTY_EXP
		useAmazonStreamBountyExp().tagAmazonBountyUrl(clickoutContext?.providerId, this);

		this.setContexts(clickoutContext);
	}

	/** Reference to the underlying URL class. */
	get url() {
		return this.#url.url;
	}

	/** Returns any error that may have happened during instantiation. */
	get error() {
		return this.#error;
	}

	/**
	 * Set a URL parameter, if the value is falsy, the parameter will not be added.
	 * **Note that parameter values are automatically encoded.**
	 */
	set(parameter: 'r', value: string): ClickoutUrl;
	set(parameter: 'cx', value: string): ClickoutUrl;
	/** Domain session ID */
	set(parameter: 'sid', value: Maybe<string>): ClickoutUrl;
	set(parameter: 'uct_buybox', value: BuyboxParam): ClickoutUrl;
	set(parameter: 'uct_country', value: string): ClickoutUrl;
	set(parameter: 'uct_ct', value: Maybe<string>): ClickoutUrl;
	set(parameter: 'uct_itsct', value: Maybe<string>): ClickoutUrl;
	set(parameter: 'uct_sport', value: Maybe<SportType>): ClickoutUrl;
	set(parameter: 'uct_tryout', value: number): ClickoutUrl;
	set(parameter: 'utm_campaign', value: string): ClickoutUrl;
	set(parameter: 'utm_medium', value: string): ClickoutUrl;
	set(parameter: 'utm_source', value: string): ClickoutUrl;
	set(parameter: 'uct_web_app_version', value: string): ClickoutUrl;
	set(parameter: 'uct_sr', value: string): ClickoutUrl;
	set(parameter: ClickoutUrlParam, value: Maybe<string | number>): ClickoutUrl {
		this.#url.set(parameter, value?.toString());

		return this;
	}

	/** Get back the value of a parameter on the Clickout URL. */
	get(parameter: ClickoutUrlParam) {
		return this.#url.get(parameter);
	}

	/**
	 * Helper for setting additional contexts, already includes `TrackingHelper.getAdditionalContexts`
	 *
	 * @param contexts Single or array of SnowplowContexts to include in the URL
	 */
	setContexts(contexts: SnowplowContext | SnowplowContext[] = []) {
		const allContexts = TrackingHelper.getAdditionalContexts();
		Array.isArray(contexts) ? allContexts.concat(contexts) : allContexts.push(contexts);

		this.set('cx', encodeContexts(allContexts));

		return this;
	}

	/** Returns the Clickout URL as a string. */
	toString() {
		return this.#url.toString();
	}
}

function encodeContexts(contexts: SnowplowContext[]): string {
	const completeContext = {
		schema: 'iglu:com.snowplowanalytics.snowplow/contexts/jsonschema/1-0-0',
		data: contexts.filter(c => c != null).map(c => c.toObject()),
	};

	try {
		const contextString = JSON.stringify(completeContext);
		return base64EncodeUrl(btoa(decodeURIComponent(encodeURIComponent(contextString))));
	} catch (error) {
		console.error('[OfferHelper] encodeContexts: Failed to generate encoded context string: ', error);
		return '';
	}
}
