import type { ClickoutUrl } from '@/helpers/clickout-helper';

import { getVm } from '@/helpers/vm-helper';
import { useExperiment } from '@/helpers/composables/useExperiment';
import { useUserAgent } from '@/helpers/composables/useUserAgent';
import { useLanguageStore, useUserStore } from '@/helpers/composables/useStores';

import { computed } from 'vue';

// ?preferred_variant=AMAZON_STREAM_BOUNTY_EXP:control
// ?preferred_variant=AMAZON_STREAM_BOUNTY_EXP:variant_1
// ?remove_preferred_experiment=AMAZON_STREAM_BOUNTY_EXP

const PRIME_VIDEO_ID = 9 as const;
const PRIME_VIDEO_SHORT_NAME = 'amp' as const;

/**
 * Experiment to track prime video clicks and see our impact on starting streams for the
 * Amazon Prime Video Stream bounty program.
 */
export function useAmazonStreamBountyExp() {
	const { country } = useLanguageStore();

	const { activeVariant, trackEvent } = useExperiment({
		name: 'AMAZON_STREAM_BOUNTY_EXP',
		variants: {
			/** Control: No behavior change but adds tracking tag. */
			CONTROL: 'control',
			/** V1: Adds tracking tag + autoplay query param */
			VARIANT_1: 'variant_1',
		} as const,
		shouldTrigger: () => country.value === 'US',
	});

	const { deviceType } = useUserAgent();

	const trackingTag = computed(() => {
		if (activeVariant.value == null) return '';

		const route = getVm().$route;

		const device = deviceType.value === 'desktop' ? 'des' : 'mob';

		// prettier-ignore
		const page =
			route.name == null ? '' :
            route.name.includes('home') ? 'home' :
            route.name === 'app.detail.movies' ? 'movie' :
            route.name === 'app.detail.show' ? 'show' :
            route.name === 'app.detail.show.season' ? 'season' :
            route.name.startsWith('app.titles.popular') ? 'pop' :
            route.name.startsWith('app.titles.new') ? 'new' :
            route.name === 'app.search.list' ? 'search' :
            route.name.startsWith('app.lists') ? 'watch' :
            '';

		// prettier-ignore
		const variant =
            activeVariant.value === 'control' ? 'c' :
            activeVariant.value === 'variant_1' ? 'v1' :
            activeVariant.value === 'variant_2' ? 'v2' :
            ''

		if (page === '' || variant === '') return '';

		return `just${device}${page}${variant}-20`;
	});

	/** True if the user has prime video specfically in their filters */
	const { settingsProvider } = useUserStore();
	const hasPrimeVideoSelected = computed(() => settingsProvider.value.includes(PRIME_VIDEO_SHORT_NAME));

	function addTrackingTag(offerUrl: string) {
		return addStringUrlTag(offerUrl, 'tag', trackingTag.value);
	}

	function addAutoplayTag(offerUrl: string) {
		return addStringUrlTag(offerUrl, 'autoplay', '1');
	}

	function tagAmazonBountyUrl(providerId: number | undefined, url: ClickoutUrl) {
		if (activeVariant.value === null || providerId !== PRIME_VIDEO_ID || trackingTag.value === '') return url;

		let offerUrl = url.get('r');
		if (offerUrl === null) return url;

		offerUrl = addTrackingTag(offerUrl);

		if (activeVariant.value === 'variant_1' && hasPrimeVideoSelected.value) {
			offerUrl = addAutoplayTag(offerUrl);
		}

		return url.set('r', offerUrl);
	}

	return { tagAmazonBountyUrl, activeVariant, trackEvent };
}

function addStringUrlTag(url: string, tag: string, value: string) {
	const u = new URL(url);
	u.searchParams.set(tag, value);

	return u.toString();
}
